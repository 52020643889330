var lastPathName = "";
var intervalVal = "";

$(function () {
    // setInterval(timerFunction, 1);
});

function timerFunction() {
    // if (location.pathname != lastPathName) {
    //     lastPathName = location.pathname;
    //     if (location.pathname == "/survey") {
    //         surveyPage();
    //     }
    //     if (location.pathname == "/about") {
    //         aboutPage();
    //     }
    //     if (location.pathname.search("/survey") != -1) {
    //         prioritiesPage();
    //     }
    // }
}

function surveyPage() {
    // var emojiInterval = setInterval(function () {
    //     if ($(".FB_reactions").length >= 1) {
    //         clearInterval(emojiInterval);

    //         $(".FB_reactions").facebookReactions();

    //         $(".emoji").click(function () {
    //             $(this).parents('a.FB_reactions').prev().val($(this).data("emoji-value"));
    //         });

    //         $('.FB_reactions').hover(function () {
    //             $(this).parent().children('.rangeslider').css('width', '30%');
    //         }, function () {
    //             $(this).parent().children('.rangeslider').css('width', '72%');
    //         });

    //         $(".comment img").on('click', function () {
    //             $(this).parent().children('.comment input').toggleClass('commenteopen');
    //             $(this).parent().parent().children('.rangeslider').toggleClass('widthsize');
    //         });
    //     }
    // }, 1);

    // var rangeInterval = setInterval(function () {
    //     if ($(".range").length >= 1) {
    //         clearInterval(rangeInterval);
    //         $(".range").ionRangeSlider({
    //             min: 0,
    //             max: 100
    //         });
    //     }
    // }, 1);
}

function aboutPage() {
    // var edulvlInterval = setInterval(function () {
    //     if ($("#edulvl").length >= 1) {
    //         clearInterval(edulvlInterval);

    //         $("#edulvl").ionRangeSlider({
    //             grid: true,
    //             from: 0,
    //             values: [
    //                 "Under Graduate", "Graduate", "Post Graduate", "Doctorate"
    //             ],
    //             onChange: function (data) {
    //                 $("#edulvl").val(data.from_value);
    //             },
    //         });
    //     }
    // }, 1);

    // var joblvlInterval = setInterval(function () {
    //     if ($("#joblvl").length >= 1) {
    //         clearInterval(joblvlInterval);

    //         $("#joblvl").ionRangeSlider({
    //             grid: true,
    //             from: 0,
    //             values: [
    //                 "Beginner", "Intermediate", "Senior", "Manager"
    //             ]
    //         });
    //     }
    // }, 1);
}

function prioritiesPage() {
    //if (location.pathname == "/about") {
    // var prioritiesInterval = setInterval(function () {
    //     if ($("[data-ui-slider]").length != 0) {
    //         clearInterval(prioritiesInterval);
    //         $('[data-ui-slider]').slider();
    //         $('[data-toggle="tooltip"]').tooltip({
    //             'placement': 'bottom'
    //         });
    //         $('[data-ui-slider]').slider("disable");
    //     }
    // }, 100);
    //}

    // var rangeInterval = setInterval(function () {
    //     if ($(".range").length >= 1) {
    //         clearInterval(rangeInterval);
    //         $(".range").ionRangeSlider({
    //             min: 0,
    //             max: 100
    //             // disable: true
    //         });
    //     }
    // }, 1);
}

// Set Bootstrap tooltips.
// $(document).ready(function(){
//     $('[data-toggle="tooltip"]').tooltip(); 
// });